import React from 'react';
import { action, observable, reaction } from 'mobx';

import {
  getMarketData
} from '../lib/rest';

class CoinPriceStore {
  @observable maketPriceData = [];
  isLoggedIn = false;

  constructor(authStore, snackbar) {
    this.isLoggedIn = authStore.isLoggedIn;
    this.snackbar = snackbar;

    if (this.isLoggedIn) {
      this.getCapData();
      this.startFetchData();
    }

    reaction(
      () => ({
        isLoggedIn: authStore.isLoggedIn
      }),
      authObj => {
        this.isLoggedIn = authObj.isLoggedIn;
        console.log("authObj.isLoggedIn:", authObj.isLoggedIn);
        if (this.isLoggedIn) {
          this.getCapData();
          this.startFetchData();
        }
      }
    );
  }

  startFetchData = () => {
    clearInterval(this.hdInterval);
    this.hdInterval = setInterval(this.getCapData, 60 * 1000);
  };

  @action.bound getCapData() {
    getMarketData({})
      .then(data => {
        this.maketPriceData = data || [];
      })
      .catch(err => {
        console.log('getCapData: ', err);
        this.maketPriceData = [];
      });
  }

  @action.bound getCoinPrice(sym) {
    let symbol = sym;
    if (sym === 'pXBT') {
      symbol = 'pBTC';
    } else if (sym === 'pXBC') {
      symbol = 'pBCH';
    } else if (sym === 'pXAU') {
      symbol = 'pGOLD';
    } else if (sym === 'pXAG') {
      symbol = 'pSilver';
    }
    const data = Object.values(this.maketPriceData);
    for (let i = 0; i < data.length; i++) {
      if (data[i].ticker_symbol === symbol) {
        return data[i].price;
      }
    }
    return 0;
  }

  @action.bound showSnackMsg(msg) {
    this.snackbar({
      message: () => (
        <>
          <span>
            <b>{msg}</b>
          </span>
        </>
      )
    });
  }
}

export default (authStore, snackbar) => {
  const store = new CoinPriceStore(authStore, snackbar);
  return store;
};
