export const SYSTEM_ENV = process.env.REACT_APP_NODE_ENV;

export const AUTH_SERVER_URL = SYSTEM_ENV === 'development'
  ? process.env.REACT_APP_AUTH_SERVER_URL_DEV
  : process.env.REACT_APP_AUTH_SERVER_URL;

export const contractAddressOfPEG = SYSTEM_ENV === 'development'
  ? process.env.REACT_APP_CONTRACT_ADDRESS_OF_PEG_DEV
  : process.env.REACT_APP_CONTRACT_ADDRESS_OF_PEG_LIVE;

export const contractAddressOfpUSD = SYSTEM_ENV === 'development'
  ? process.env.REACT_APP_CONTRACT_ADDRESS_OF_PUSD_DEV
  : process.env.REACT_APP_CONTRACT_ADDRESS_OF_PUSD_LIVE;

export const contractAddressOfBridge = SYSTEM_ENV === 'development'
  ? process.env.REACT_APP_CONTRACT_ADDRESS_OF_BRIDGE_DEV
  : process.env.REACT_APP_CONTRACT_ADDRESS_OF_BRIDGE_LIVE;

export const ETHERSCAN_URL = SYSTEM_ENV === 'development'
  ? process.env.REACT_APP_ETHERSCAN_DEV
  : process.env.REACT_APP_ETHERSCAN_LIVE;

export const WS = {
  REAL: {
    HOST: SYSTEM_ENV === 'development'
      ? process.env.REACT_APP_WS_PRIVATE_HOST_DEV
      : process.env.REACT_APP_WS_PRIVATE_HOST,
    PORT: process.env.REACT_APP_WS_PRIVATE_PORT,
  },
};
