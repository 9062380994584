/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => (checkIsActive(location, url)
    ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
    : '');

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* begin::1 Level */}
        <li className="menu-section">
          <h4 className="menu-text">DASHBOARD</h4>
          <i className="menu-icon flaticon-more-v2" />
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/dashboard/wallet', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard/wallet">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">My Factom Wallet</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/dashboard/ewallet', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard/ewallet">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Devices/CPU1.svg')} />
            </span>
            <span className="menu-text">My Ethereum Wallet</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/dashboard/gateway', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard/gateway">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Difference.svg')} />
            </span>
            <span className="menu-text">Gateway (CrossChain)</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/dashboard/history', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard/history">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Files/File.svg')} />
            </span>
            <span className="menu-text">History</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/dashboard/exchanges', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard/exchanges">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Smile.svg')} />
            </span>
            <span className="menu-text">Exchanges</span>
          </NavLink>
        </li>
        {/* end::1 Level */}

        {/* Custom */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">TRADING BOTS</h4>
          <i className="menu-icon flaticon-more-v2" />
        </li>
        {/* end:: section */}
        <li
          className={`menu-item ${getMenuItemActive('/trading/trading', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/trading/trading">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Settings4.svg')} />
            </span>
            <span className="menu-text">Trading View Script</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/trading/arbitrage', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/trading/arbitrage">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Commode1.svg')} />
            </span>
            <span className="menu-text">Arbitrage</span>
          </NavLink>
        </li>

        {/* Custom */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">SETTINGS</h4>
          <i className="menu-icon flaticon-more-v2" />
        </li>
        {/* end:: section */}
        <li
          className={`menu-item ${getMenuItemActive('/setting/security', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/setting/security">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Lock-circle.svg')} />
            </span>
            <span className="menu-text">Security</span>
          </NavLink>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
