import React, { useState } from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Web3 from 'web3';

import { STORE_KEYS } from '../../stores';
import { SYSTEM_ENV, AUTH_SERVER_URL } from '../../config/constants';
import { handleSuccess } from '../../component/Toast';

const useStyles = makeStyles((theme) => ({
  cardBody: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0px 10px 50px',
    textAlign: 'center',
  },
  iconMetamask: {
    margin: 'auto',
  },
}));

let web3 = undefined; // Will hold the web3 instance

function Metamask(props) {
  const classes = useStyles();
  const {
    loginWithMetamask,
  } = props;
  const [loading, setLoading] = useState(false); // Loading button state

  const handleAuthenticate = ({
                                publicAddress,
                                signature,
                              }) =>
    fetch(`${AUTH_SERVER_URL}/api/auth02/login`, {
      body: JSON.stringify({ publicAddress, signature }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }).then((response) => response.json());

  const handleSignMessage = async ({
                                     publicAddress,
                                     nonce,
                                   }) => {
    try {
      const signature = await web3?.eth.personal.sign(
        `pTrader requires signing one-time nonce: ${nonce}`,
        publicAddress,
        '' // MetaMask will ignore the password argument here
      );

      return { publicAddress, signature };
    } catch (err) {
      console.log('err:', err);
      throw new Error(
        'You need to sign the message to be able to log in.'
      );
    }
  };

  const handleSignup = (publicAddress) =>
    fetch(`${AUTH_SERVER_URL}/api/auth02/register`, {
      body: JSON.stringify({ publicAddress }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }).then((response) => response.json());

  const handleClick = async () => {
    // Check if MetaMask is installed
    if (!window.ethereum) {
      handleSuccess('Please install MetaMask first.');
      return;
    }

    if (!web3) {
      try {
        // Request account access if needed
        await window.ethereum.enable();

        // We don't know window.web3 version, so we use our own instance of Web3
        // with the injected provider given by MetaMask
        web3 = new Web3(window.ethereum);
      } catch (error) {
        handleSuccess('You need to allow MetaMask.');
        return;
      }
    }

    const coinbase = await web3.eth.getCoinbase();
    if (!coinbase) {
      handleSuccess('Please activate MetaMask first.');
      return;
    }

    if (SYSTEM_ENV === 'development' && window.ethereum.chainId !== '0x4') {
      handleSuccess('Please use Rinkeby Test network.');
      return;
    } else if (SYSTEM_ENV !== 'development' && window.ethereum.chainId !== '0x1') {
      handleSuccess('Please use Ethereum Main Network (Mainnet).');
      return;
    }

    const publicAddress = coinbase.toLowerCase();
    setLoading(true);

    // Look if user with current publicAddress is already present on backend
    fetch(
      `${AUTH_SERVER_URL}/api/auth02/users?publicAddress=${publicAddress}`
    )
      .then((response) => response.json())
      // If yes, retrieve it. If no, create it.
      .then((users) => users.length ? users[0] : handleSignup(publicAddress))
      // Popup MetaMask confirmation modal to sign message
      .then(handleSignMessage)
      // Send signature to backend on the /auth route
      .then(handleAuthenticate)
      // Pass accessToken back to parent component (to save it in localStorage)
      .then(loginWithMetamask)
      .catch((err) => {
        handleSuccess(err);
        setLoading(false);
      });
  };

  return (
    <div className="login-form login-signin card card-custom" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="card-header  d-flex justify-content-center border-bottom-0 pt-10">
        <div className="card-title d-flex align-items-center flex-column">
          <h1 className="font-weight-bolder text-dark mb-1">
            Connect Ethereum wallet
          </h1>
          <span className="text-muted font-weight-bold font-size-sm">
            You need an Ethereum wallet to use pTrader.
          </span>
        </div>
      </div>
      {/* end::Head */}

      <div className={classes.cardBody}>
        <img src={"/media/logos/icon_metamask.png"} alt="metamask" className={classes.iconMetamask} />
        {window.ethereum ? (
          <div>
            <button className="btn btn-primary" onClick={handleClick}>
              {loading ? 'Loading...' : 'Login with MetaMask'}
            </button>
          </div>
        ) : (
          <a href={"https://metamask.io/download.html"} target={"_blank"} className="btn btn-primary">
            GET METAMASK
          </a>
        )}
      </div>
    </div>
  );
}

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.AUTHSTORE]: { loginWithMetamask },
     }) => ({
      loginWithMetamask,
    }),
  ),
)(Metamask);
