import React, { Suspense, lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';

import { STORE_KEYS } from './stores';
import { ContentRoute, LayoutSplashScreen } from './core/layout';

const Dashboard = lazy(() => import('./pages/Dashboard'));

const Setting = lazy(() => import('./pages/Settings'));

const Trading = lazy(() => import('./pages/Trading'));

const WelcomeModal = lazy(() => import('./component/Modals/WelcomeModal'));

const BasePage = (props) => {
  const { openOnboard, setOpenOnboard } = props;

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard/wallet" />
        <ContentRoute path="/dashboard" component={Dashboard} />
        <ContentRoute path="/setting" component={Setting} />
        <ContentRoute path="/trading" component={Trading} />

        <Redirect to="error/error-v1" />
      </Switch>
      <WelcomeModal
        isOpen={openOnboard}
        handleModal={setOpenOnboard}
      />
    </Suspense>
  );
};

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.AUTHSTORE]: {
        openOnboard,
        setOpenOnboard,
      },
    }) => ({
      openOnboard,
      setOpenOnboard,
    }),
  ),
)(BasePage);
