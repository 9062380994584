import { toast } from 'react-toastify';
import ToastBody from './ToastBody';

const toastConfig = {
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: 5000,
  closeButton: false,
};

const handleSuccess = (info) => {
  toast.success(ToastBody({ type: 'SUCCESS', info }), {
    containerId: 'A',
    ...toastConfig,
  });
};

const handleInfo = (info) => {
  toast.info(ToastBody({ type: 'INFORMATION', info }), {
    containerId: 'B',
    ...toastConfig,
  });
};

const handleWarning = (info) => {
  toast.warn(ToastBody({ type: 'WARNING', info }), {
    containerId: 'C',
    ...toastConfig,
  });
};

const handleError = (info) => {
  toast.error(ToastBody({ type: 'ERROR', info }), {
    containerId: 'D',
    ...toastConfig,
  });
};

export {
  handleError,
  handleWarning,
  handleInfo,
  handleSuccess,
};
