import React, { useMemo } from 'react';
import objectPath from 'object-path';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import SVG from 'react-inlinesvg';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { toAbsoluteUrl } from '../../../_helpers';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { SearchDropdown } from '../extras/dropdowns/search/SearchDropdown';
import { UserNotificationsDropdown } from '../extras/dropdowns/UserNotificationsDropdown';
import UserDropdown from '../extras/dropdowns/UserDropdown';
// import { QuickActionsDropdown } from '../extras/dropdowns/QuickActionsDropdown';
// import { MyCartDropdown } from '../extras/dropdowns/MyCartDropdown';
// import { QuickUserToggler } from '../extras/QuiclUserToggler';

export function Topbar() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => ({
    viewSearchDisplay: objectPath.get(
      uiService.config,
      'extras.search.display',
    ),
    viewNotificationsDisplay: objectPath.get(
      uiService.config,
      'extras.notifications.display',
    ),
    viewLanguagesDisplay: objectPath.get(
      uiService.config,
      'extras.languages.display',
    ),
    viewUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),
  }), [uiService]);

  return (
    <div className="topbar">
      {layoutProps.viewSearchDisplay && <SearchDropdown />}

      {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />}

      {layoutProps.viewLanguagesDisplay && <UserDropdown />}
    </div>
  );
}
