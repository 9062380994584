import axios from 'axios';

import { AUTH_SERVER_URL } from '../../config/constants';

const getHeaders = () => ({
  headers: {
    'Content-Type': 'application/json',
  },
});

export const loginWithEmail = (email, password) => axios.post(`${AUTH_SERVER_URL}/api/auth/login`, { email, password }, getHeaders())
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response?.data || err.response));

export const existEmail = (email) => axios.post(`${AUTH_SERVER_URL}/api/auth/isEmail`, { email }, getHeaders())
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response?.data || err.response));

export const resetPasswordRequest = (email) => axios.post(`${AUTH_SERVER_URL}/api/auth/reset_password_request`, { email }, getHeaders())
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response?.data || err.response));

export const resetPassword = (password, token, tfaCode) => axios.post(`${AUTH_SERVER_URL}/api/auth/reset_password`, { password, token, tfaCode }, getHeaders())
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response?.data || err.response));

export const registerWithEmail = (name, email, password) => axios.post(`${AUTH_SERVER_URL}/api/auth/register`, { name, email, password }, getHeaders())
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response?.data || err.response));

export const confirmEmail = (email, token) => axios.post(`${AUTH_SERVER_URL}/api/auth/verify`, { email, token }, getHeaders())
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response?.data || err.response));

export const getMarketData = () => axios.get('https://pegnetmarketcap.com/api/asset/all')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response?.data || err.response));
