import React from 'react';
import { action, observable, reaction } from 'mobx';

import {
  HistoryRequest,
} from '../lib/ws';

class HistoryStore {
  @observable txHistoryData = [];

  @observable isLoggedIn = false;

  fctAddr = '';

  constructor(authStore, snackbar, realDepositStore) {
    this.isLoggedIn = authStore.isLoggedIn;
    this.snackbar = snackbar;

    if (this.isLoggedIn) {
      this.getTxHistory();
    }

    reaction(
      () => ({
        isLoggedIn: authStore.isLoggedIn,
      }),
      (authObj) => {
        this.isLoggedIn = authObj.isLoggedIn;
        console.log('authObj.isLoggedIn:', authObj.isLoggedIn);
        if (this.isLoggedIn) {
          this.getTxHistory();
        }
      },
    );

    reaction(
      () => ({
        fctAddr: realDepositStore.fctAddr,
      }),
      (realDepositStoreObj) => {
        this.fctAddr = realDepositStoreObj.fctAddr;
        this.getTxHistory();
      },
    );
  }

  @action.bound getTxHistory() {
    if (!this.fctAddr) return;
    HistoryRequest({
      fromFCTWallet: this.fctAddr,
    })
      .then((data) => {
        if (!data.error) {
          try {
            const txData = data.data.result.actions || [];
            this.txHistoryData = txData;
            // console.log('this.txHistoryData:', this.txHistoryData);
          } catch (e) {
            this.txHistoryData = [];
          }
        } else {
          this.txHistoryData = [];
        }
      })
      .catch((err) => {
        console.log('getTxHistory: ', err);
        this.txHistoryData = [];
      });
  }

  @action.bound showSnackMsg(msg) {
    this.snackbar({
      message: () => (
        <>
          <span>
            <b>{msg}</b>
          </span>
        </>
      ),
    });
  }
}

export default (authStore, snackbar, realDepositStore) => {
  const store = new HistoryStore(authStore, snackbar, realDepositStore);
  return store;
};
