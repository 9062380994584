import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SingleInput from './SingleInput';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  minus: {
    width: 15,
    height: 1,
    background: 'black',
    margin: theme.spacing(2),
  },
}));

const EmailInputGroup = ({
  onChange,
}) => {
  const classes = useStyles();
  const [codes, setCodes] = useState([]);

  const handlePaste = (event) => {
    event.clipboardData.items[0].getAsString((text) => {
      const values = [...text.slice(0, 6)];
      setCodes(values);
      onChange(values.join(''));
    });
  };

  const handleChange = (key) => (value) => {
    const values = [...codes];
    values.splice(key, 1);
    values.splice(key, 0, value);
    setCodes(values);
    onChange(values.join(''));
  };

  return (
    <div className={classes.root} onPaste={handlePaste}>
      <SingleInput order="first" value={codes[0]} onChange={handleChange(0)} />
      <SingleInput order="middle" value={codes[1]} onChange={handleChange(1)} />
      <SingleInput order="middle" value={codes[2]} onChange={handleChange(2)} />
      <span className={classes.minus} />
      <SingleInput order="middle" value={codes[3]} onChange={handleChange(3)} />
      <SingleInput order="middle" value={codes[4]} onChange={handleChange(4)} />
      <SingleInput order="last" value={codes[5]} onChange={handleChange(5)} />
    </div>
  );
};

export default EmailInputGroup;
