/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect } from 'react';
import objectPath from 'object-path';
import { useLocation } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { makeStyles } from '@material-ui/core/styles';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import { getBreadcrumbsAndTitle, useSubheader } from '../../_core/MetronicSubheader';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { STORE_KEYS } from '../../../../stores';

const useStyles = makeStyles(() => ({
  addressLabel: {
    fontSize: 14,
    '&::before': {
      borderBottom: '0px !important',
    },
  },
}));

const SubHeader = ({
  fctAddr,
  fctAddrList,
  setActiveFCTWallet,
  publicAddress,
}) => {
  const classes = useStyles();
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  const layoutProps = useMemo(() => ({
    config: uiService.config,
    subheaderMobileToggle: objectPath.get(
      uiService.config,
      'subheader.mobile-toggle',
    ),
    subheaderCssClasses: uiService.getClasses('subheader', true),
    subheaderContainerCssClasses: uiService.getClasses(
      'subheader_container',
      true,
    ),
  }), [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle('kt_aside_menu', location.pathname);
    const header = getBreadcrumbsAndTitle('kt_header_menu', location.pathname);
    const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle((aside && aside.title && aside.title.length > 0) ? aside.title : header.title);
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center
         justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          <div className="d-flex align-items-baseline mr-5">
            <h5 className="text-dark font-weight-bold my-2 mr-5">
              {subheader.title}
            </h5>
          </div>
        </div>
        {
          (subheader.title === 'My Factom Wallet'
            || subheader.title === 'Gateway (CrossChain)'
            || subheader.title === 'History') && (
            <Select
              className={classes.addressLabel}
              value={fctAddr}
              onChange={(e) => {
                setActiveFCTWallet(fctAddrList.indexOf(e.target.value));
              }}
            >
              {
                fctAddrList.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))
              }
            </Select>
          )
        }
        {
          subheader.title === 'My Ethereum Wallet' && (
            <Select
              className={classes.addressLabel}
              value={publicAddress}
            >
              <MenuItem value={publicAddress}>
                {publicAddress}
              </MenuItem>
            </Select>
          )
        }
      </div>
    </div>
  );
};

export default compose(
  inject(STORE_KEYS.WEB3STORE, STORE_KEYS.REALDEPOSITSTORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.WEB3STORE]: {
        publicAddress,
      },
      [STORE_KEYS.REALDEPOSITSTORE]: {
        fctAddr,
        fctAddrList,
        setActiveFCTWallet,
      },
    }) => ({
      publicAddress,
      fctAddr,
      fctAddrList,
      setActiveFCTWallet,
    }),
  ),
)(SubHeader);
