import React from 'react';
import { observable, action, reaction } from 'mobx';
import {
  GetSecurityRequest, Verify2FARequest, Enable2FARequest, Disable2FARequest,
  ExportAccountRequest, VerifyPassRequest, ImportAccountRequest,
} from '../lib/ws';
import { handleWarning, handleSuccess } from '../component/Toast';
import { toBinary } from '../utils';

class SecurityStore {
  @observable isTry2fa = false;

  @observable is_tfa = false;

  @observable tfaStr = '';

  @observable publicAddr = '';

  @observable secret = '';

  @observable exportStep = 'INIT';

  @observable importStep = 'INIT';

  isLoggedIn = false;

  fctAddr = '';

  constructor(authStore, snackbar, realDepositStore) {
    this.snackbar = snackbar;
    this.isLoggedIn = authStore.isLoggedIn;

    if (this.isLoggedIn) {
      this.getSecurityRequest();
    }

    reaction(
      () => ({
        isLoggedIn: authStore.isLoggedIn,
      }),
      (authObj) => {
        this.isLoggedIn = authObj.isLoggedIn;
        console.log('authObj.isLoggedIn:', authObj.isLoggedIn);
        if (this.isLoggedIn) {
          this.getSecurityRequest();
        }
      },
    );

    reaction(
      () => ({
        fctAddr: realDepositStore.fctAddr,
      }),
      (realDepositStoreObj) => {
        this.fctAddr = realDepositStoreObj.fctAddr;
      },
    );
  }

  @action.bound handleChangeSwitch() {
    this.isTry2fa = !this.isTry2fa;
    if (this.isTry2fa) {
      this.enable2FARequest();
    }
  }

  @action.bound getSecurityRequest() {
    GetSecurityRequest()
      .then((data) => {
        this.is_tfa = data.is_tfa || false;
      })
      .catch(() => {
        this.is_tfa = false;
      });
  }

  @action.bound exportAccount(address, signature) {
    ExportAccountRequest({ signature, fromFCTWallet: address })
      .then((data) => {
        if (!data.error) {
          this.exportStep = 'COMPLETED';
          this.publicAddr = data.publicA || '';
          this.secret = data.privateA || '';
          handleSuccess(data.message);
        } else {
          handleWarning(data.error);
        }
      });
  }

  @action.bound importAccount(address, signature) {
    return new Promise((resolve, reject) => {
      ImportAccountRequest({ signature, fromFCTWallet: btoa(toBinary(address)) })
        .then((data) => {
          if (!data.error) {
            this.importStep = 'COMPLETED';
            handleSuccess(data.message);
            resolve(true);
          } else {
            handleWarning(data.error);
            resolve(true);
          }
        })
        .catch(() => {
          handleWarning('Import wallet is failed');
          resolve(true);
        });
    });
  }

  @action.bound resetKeys() {
    this.exportStep = 'INIT';
    this.importStep = 'INIT';
    this.publicAddr = '';
    this.secret = '';
  }

  @action.bound verifyPass(pass) {
    VerifyPassRequest({ pass })
      .then((data) => {
        if (!data.error) {
          this.exportStep = 'CONFIRM';
        } else {
          handleWarning(data.error);
        }
      });
  }

  @action.bound verify2FARequest(code) {
    Verify2FARequest({ code })
      .then((data) => {
        if (!data.error) {
          handleSuccess(data.message);
          this.is_tfa = true;
        } else {
          handleWarning(data.error);
        }
      });
  }

  @action.bound enable2FARequest() {
    Enable2FARequest()
      .then((data) => {
        if (!data.error) {
          this.tfaStr = data.dataUrl || '';
        } else {
          handleWarning(data.error);
        }
      });
  }

  @action.bound disable2FARequest() {
    Disable2FARequest()
      .then((data) => {
        if (!data.error) {
          handleSuccess(data.message);
        } else {
          handleWarning(data.error);
        }
      });
  }

  /**
   *  Snackbar Popup message
   */
  @action.bound showSnackMsg(msg) {
    this.snackbar({
      message: () => (
        <>
          <span>
            <b>{msg}</b>
          </span>
        </>
      ),
    });
  }
}

export default (authStore, snackbar, realDepositStore) => new SecurityStore(authStore, snackbar, realDepositStore);
