import React from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import history from '../../history';
import { STORE_KEYS } from '../../stores';
import { handleWarning } from '../../component/Toast';

const initialValues = {
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
};

function Registration(props) {
  const { intl, registerWithMail, setPendingEmail } = props;
  const RegistrationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      )
      .when('password', {
        is: (val) => (!!(val && val.length > 0)),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          "Password and Confirm Password didn't match",
        ),
      }),
    acceptTerms: Yup.bool().required(
      'You must accept the terms and conditions',
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      registerWithMail(values.email, values.password)
        .then(() => {
          setPendingEmail(values.email);
          setSubmitting(false);
          history.push('/auth/check_email');
        })
        .catch(() => {
          setSubmitting(false);
          handleWarning('Sorry, your registration is failed.');
        });
    },
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  return (
    <div className="login-form login-signin" style={{ display: 'block' }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          Get Your Free Account
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your&nbsp;
          <strong>email address</strong>
          &nbsp;and&nbsp;
          <strong>password</strong>
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="You@example.com"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'email',
            )}`}
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'password',
            )}`}
            name="password"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="retype password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'changepassword',
            )}`}
            name="changepassword"
            {...formik.getFieldProps('changepassword')}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox align-items-start">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps('acceptTerms')}
            />
            <span style={{ minWidth: 18, marginRight: 8 }} />
            <p>
              By clicking accept, you’re agreeing to our&nbsp;
              <Link to="/terms" target="_blank" className="mr-1" rel="noopener noreferrer">
                Customer Terms of Service,&nbsp;
              </Link>
              <Link to="/gdpr-privacy-policy" target="_blank" className="mr-1" rel="noopener noreferrer">
                Privacy Policy,&nbsp;
              </Link>
              and&nbsp;
              <Link to="/gdpr-privacy-policy" target="_blank" className="mr-1" rel="noopener noreferrer">
                Cookie Policy.
              </Link>
            </p>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting || !formik.values.acceptTerms}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Sign Up with Email</span>
            {formik.isSubmitting && <span className="ml-3 spinner spinner-white" />}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.AUTHSTORE]: { registerWithMail, setPendingEmail },
    }) => ({
      registerWithMail,
      setPendingEmail,
    }),
  ),
)(injectIntl(Registration));
