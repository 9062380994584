import React from 'react';
import Alert from '@material-ui/lab/Alert';

const ToastState = {
  toastStatus: {
    SUCCESS: 'success',
    ERROR: 'danger',
    INFORMATION: 'info',
    WARNING: 'warning',
  },
};

const ToastBody = ({ type, info }) => (
  <Alert variant="filled" severity={ToastState.toastStatus[type]}>
    {`${info}`}
  </Alert>
);

export default ToastBody;
