/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';

import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../../../_helpers';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';
import { STORE_KEYS } from '../../../../../stores';

function UserDropdown({logout}) {
  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
      >
        <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span className="svg-icon svg-icon-xl svg-icon-primary">
            <SVG
              src={toAbsoluteUrl(
                '/media/svg/icons/General/User.svg',
              )}
            />
          </span>
          {/* <img */}
          {/*  className="h-25px w-25px rounded" */}
          {/*  src={currentLanguage.flag} */}
          {/*  alt={currentLanguage.name} */}
          {/* /> */}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
        <ul className="navi navi-hover py-4">
          <li className="navi-item">
            <Link
              to="/setting/security"
              onClick={() => {console.log('Clicked')}}
              className="navi-link"
            >
              <span className="symbol symbol-20 mr-3">
                <i className="fa fa-lock text-primary" />
              </span>
              <span className="navi-text">Security</span>
            </Link>
          </li>

          <li className="navi-item">
            <Link
              onClick={logout}
              className="navi-link"
            >
              <span className="symbol symbol-20 mr-3">
                <i className="fa fa-sign-out-alt text-primary" />
              </span>
              <span className="navi-text">Logout</span>
            </Link>
          </li>
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default compose(
  inject(STORE_KEYS.WEB3STORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.WEB3STORE]: { logout },
    }) => ({
      logout,
    }),
  ),
)(UserDropdown);
