import React, { useState } from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';

import { STORE_KEYS } from '../../stores';
import EmailInputGroup from '../../component/EmailInputGroup';
import history from '../../history';
import { handleWarning } from '../../component/Toast';

const CheckEmail = ({
  pendingEmail,
  confirmMail,
}) => {
  const [code, setCode] = useState('');

  const confirmSubmit = (event) => {
    event.preventDefault();
    if (code.length === 6) {
      confirmMail(pendingEmail, code)
        .then(() => {
          history.push('/');
        })
        .catch(() => {
          handleWarning('Sorry, your confirmation is failed.');
        });
    }
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          Check your email!
        </h3>
      </div>
      {/* end::Head */}

      {/* begin::Form */}
      <form
        onSubmit={confirmSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <p className="text-left">
          {`We've sent a 6-digit confirmation code to ${pendingEmail}.
          It will expire shortly, so enter it soon.`}
        </p>
        <div className="d-flex justify-content-center align-items-center pt-5 pb-5">
          <EmailInputGroup
            onChange={(value) => setCode(value)}
          />
        </div>
        <p className="text-left">
          Keep this window open while checking for your code. Remember to try your spam folder!
        </p>
        <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={false}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3"
          >
            <span>Confirm</span>
            <span className="ml-3 spinner spinner-white" />
          </button>
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.AUTHSTORE]: { pendingEmail, confirmMail },
    }) => ({
      pendingEmail,
      confirmMail,
    }),
  ),
)(CheckEmail);
