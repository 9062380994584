/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../../_helpers';

export function QuickPanel() {
  const [selectedTab, setSelectedTab] = useState('AuditLogs');

  const setTab = (_tabName) => {
    setSelectedTab(_tabName);
  };

  return (
    <div id="kt_quick_panel" className="offcanvas offcanvas-right pt-5 pb-10">
      <Tab.Container
        defaultActiveKey={selectedTab}
      >
        {/* begin::Header */}
        <div
          className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5"
        >
          <Nav
            onSelect={setTab}
            as="ul"
            role="tablist"
            className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
          >
            <Nav.Item as="li">
              <Nav.Link
                eventKey="AuditLogs"
                className={`nav-link ${
                  selectedTab === 'AuditLogs' ? 'active' : ''
                }`}
              >
                Tab #1
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-item" as="li">
              <Nav.Link
                eventKey="Notifications"
                className={`nav-link ${
                  selectedTab === 'Notifications' ? 'active' : ''
                }`}
              >
                Tab #2
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link
                eventKey="Settings"
                className={`nav-link ${
                  selectedTab === 'Settings' ? 'active' : ''
                }`}
              >
                Tab #3
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <div className="offcanvas-close mt-n1 pr-5" style={{ position: 'absolute', top: '15px', right: '10px' }}>
            <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_panel_close"
            >
              <i className="ki ki-close icon-xs text-muted" />
            </a>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Content */}
        <div className="offcanvas-content px-10">
          <div className="tab-content">
            <div
              id="kt_quick_panel_logs"
              role="tabpanel"
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll ps ${selectedTab === 'AuditLogs' ? 'active show' : ''}`}
            >
              <div className="mb-15">
                <h5 className="font-weight-bold mb-5">Content section #1</h5>
                <div className="d-flex align-items-center flex-wrap mb-5">
                  <div className="symbol symbol-50 symbol-light mr-5">
                    <span className="symbol-label">
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/006-plurk.svg')}
                        alt=""
                        className="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <a
                      href="#"
                      className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
                    >
                      Top Authors
                    </a>
                    <span className="text-muted font-weight-bold">
                      Most Successful Fellas
                    </span>
                  </div>
                  <span className="btn btn-sm btn-light font-weight-bolder py-1 my-lg-0 my-2 text-dark-50">
                    +82$
                  </span>
                </div>
              </div>
            </div>

            <div
              id="kt_quick_panel_notifications"
              role="tabpanel"
              className={`tab-pane fade pt-2 pr-5 mr-n5 scroll ps ${selectedTab === 'Notifications' ? 'active show' : ''}`}
            >
              <div className="navi navi-icon-circle navi-spacer-x-0">
                <a href="#" className="navi-item">
                  <div className="navi-link rounded">
                    <div className="symbol symbol-50 mr-3">
                      <div className="symbol-label">
                        <i className="flaticon2-box text-danger icon-lg" />
                      </div>
                    </div>
                    <div className="navi-text">
                      <div className="font-weight-bold  font-size-lg">
                        2 new items submited
                      </div>
                      <div className="text-muted">by Grog John</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div
              id="kt_quick_panel_settings"
              role="tabpanel"
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll ps ${selectedTab === 'Settings' ? 'active show' : ''}`}
            >
              <form className="form">
                <div className="pt-2">
                  <h5 className="font-weight-bold mb-3">Reports</h5>
                  <div className="form-group mb-0 row">
                    <label className="col-8 col-form-label">
                      Generate Reports:
                    </label>
                    <div className="col-4 text-right">
                      <span className="switch switch-sm switch-danger">
                        <label>
                          <input
                            type="checkbox"
                            name=""
                            onChange={() => {
                            }}
                            checked
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="separator separator-dashed my-6" />

                <div className="pt-2">
                  <h5 className="font-weight-bold mb-3">Memebers</h5>
                  <div className="form-group mb-0 row">
                    <label className="col-8 col-form-label">
                      Enable Customer Portal:
                    </label>
                    <div className="col-4 text-right">
                      <span className="switch switch-sm switch-primary">
                        <label>
                          <input
                            type="checkbox"
                            checked
                            onChange={() => {
                            }}
                            name=""
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* end::Content */}
      </Tab.Container>
    </div>
  );
}
