import React from 'react';
import { useFormik } from 'formik';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import * as Yup from 'yup';

import { STORE_KEYS } from '../../stores';

const initialValues = {
  email: '',
};

function ForgotPassword(props) {
  const { intl, resetPasswordRequest } = props;
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      resetPasswordRequest(values.email);
      setSubmitting(false);
    },
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  return (
    <>
      <div className="login-form login-forgot" style={{ display: 'block' }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Reset Password ?</h3>
          <div className="text-muted font-weight-bold">
            Enter your email address to reset your password
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {formik.status}
              </div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <input
              type="email"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                'email',
              )}`}
              name="email"
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <p>
            {`If you enabled 2FA already before, you can reset your password. Otherwise,
            you can not reset your password.`}
          </p>
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={formik.isSubmitting}
            >
              Send Password Reset Link
            </button>
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.AUTHSTORE]: { resetPasswordRequest },
    }) => ({
      resetPasswordRequest,
    }),
  ),
)(injectIntl(ForgotPassword));
