import React from 'react';
import { action, observable, reaction } from 'mobx';

import {
  DepositAddressRequest,
  PaymentAddressRequest,
  WithdrawCoinRequest,
  ConversionRequest,
  RealWithdrawConfirm,
  WithdrawPaymentRequest,
  PaymentWithdrawConfirm,
  FCTBalanceRequest,
} from '../lib/ws';
import { handleWarning, handleSuccess, handleError } from '../component/Toast';

class RealDepositStore {
  @observable userId = '';

  @observable fctAddr = '';

  @observable paymentAddr = '';

  @observable priceUpdatedAt = '';

  @observable myBalances = {};

  @observable.shallow userIdList = [];

  @observable.shallow fctAddrList = [];

  @observable.shallow myBalancesList = [];

  @observable activeWalletIndex = 0;

  isLoggedIn = false;

  hFctBalIntval = null;

  constructor(authStore, snackbar, coinPriceStore) {
    this.isLoggedIn = authStore.isLoggedIn;
    this.snackbar = snackbar;

    if (this.isLoggedIn) {
      this.getDepositAddresses();
      this.getPaymentAddress('ETH');
    }

    reaction(
      () => ({
        isLoggedIn: authStore.isLoggedIn,
      }),
      (authObj) => {
        this.isLoggedIn = authObj.isLoggedIn;
        console.log('authObj.isLoggedIn:', authObj.isLoggedIn);
        if (this.isLoggedIn) {
          this.getDepositAddresses();
          this.getPaymentAddress('ETH');
        }
      },
    );

    reaction(
      () => ({
        maketPriceData: coinPriceStore.maketPriceData,
      }),
      (marketObj) => {
        this.priceUpdatedAt = (new Date()).getTime();
      },
    );

    this.checkFctBalanceHandler();
  }

  @action.bound checkFctBalanceHandler() {
    if (!this.isLoggedIn) return;
    clearInterval(this.hFctBalIntval);
    this.hFctBalIntval = setInterval(async() => {
      this.fctBalanceRequest();
    }, 30 * 1000);
  }

  @action.bound fctBalanceRequest = () => {
    FCTBalanceRequest({
      fromFCTWallet: this.fctAddr,
    })
      .then((dt) => {
        this.myBalancesList[this.activeWalletIndex] = {
          PEG: dt.PEG || 0,
          pADA: dt.pADA || 0,
          pATOM: dt.pATOM || 0,
          pAUD: dt.pAUD || 0,
          pBAT: dt.pBAT || 0,
          pBNB: dt.pBNB || 0,
          pBRL: dt.pBRL || 0,
          pCAD: dt.pCAD || 0,
          pCHF: dt.pCHF || 0,
          pCNY: dt.pCNY || 0,
          pDASH: dt.pDASH || 0,
          pDCR: dt.pDCR || 0,
          pEOS: dt.pEOS || 0,
          pETH: dt.pETH || 0,
          pEUR: dt.pEUR || 0,
          pFCT: dt.pFCT || 0,
          pGBP: dt.pGBP || 0,
          pHKD: dt.pHKD || 0,
          pINR: dt.pINR || 0,
          pJPY: dt.pJPY || 0,
          pKRW: dt.pKRW || 0,
          pLINK: dt.pLINK || 0,
          pLTC: dt.pLTC || 0,
          pMXN: dt.pMXN || 0,
          pNOK: dt.pNOK || 0,
          pNZD: dt.pNZD || 0,
          pPHP: dt.pPHP || 0,
          pRUB: dt.pRUB || 0,
          pRVN: dt.pRVN || 0,
          pSEK: dt.pSEK || 0,
          pSGD: dt.pSGD || 0,
          pTRY: dt.pTRY || 0,
          pUSD: dt.pUSD || 0,
          pXAG: dt.pXAG || 0,
          pXAU: dt.pXAU || 0,
          pXBC: dt.pXBC || 0,
          pXBT: dt.pXBT || 0,
          pXLM: dt.pXLM || 0,
          pXMR: dt.pXMR || 0,
          pXTZ: dt.pXTZ || 0,
          pZAR: dt.pZAR || 0,
          pZEC: dt.pZEC || 0,
        };
      })
      .catch((err) => {
        handleError(err);
      });
  };

  @action.bound getPaymentAddress = (currency) => {
    const data = {
      currency,
    };
    PaymentAddressRequest(data)
      .then((data) => {
        this.paymentAddr = data.address || '';
        console.log('this.paymentAddr:', this.paymentAddr);
      })
      .catch((err) => {
        console.log('PaymentAddressRequest: ', err);
      });
  };

  @action.bound withdrawPaymentRequest = (amountOfPUSD, withdrawalAddress) => {
    const data = {
      amount: amountOfPUSD,
      address: withdrawalAddress,
    };
    WithdrawPaymentRequest(data)
      .then((dt) => {
        handleSuccess(dt.message || 'Withdraw confirmation code is sent. Please check codes on your Email');
      })
      .catch((err) => {
        handleError(err);
      });
  };

  @action.bound paymentWithdrawConfirm(token, inputCode) {
    return new Promise((resolve, reject) => {
      PaymentWithdrawConfirm({
        token,
        tfaCode: inputCode,
      })
        .then((data) => {
          if (!data.error) {
            handleSuccess(data.message);
          } else {
            handleWarning(data.error);
          }
          resolve(true);
        });
    });
  }

  @action.bound setActiveFCTWallet(index) {
    this.userId = this.userIdList[index];
    this.fctAddr = this.fctAddrList[index];
    this.myBalances = this.myBalancesList[index];
    this.activeWalletIndex = index;
    localStorage.setItem('walletIndex', index);
    this.fctBalanceRequest();
  }

  @action.bound getDepositAddresses() {
    DepositAddressRequest({})
      .then((data) => {
        if (!data.error && data?.myWallets?.length > 0) {
          this.userIdList = [];
          this.fctAddrList = [];
          this.myBalancesList = [];
          // eslint-disable-next-line array-callback-return
          data.myWallets.map((item) => {
            this.userIdList.push(item.userId);
            this.fctAddrList.push(item.address);
            this.myBalancesList.push({
              PEG: item.PEG || 0,
              pADA: item.pADA || 0,
              pATOM: item.pATOM || 0,
              pAUD: item.pAUD || 0,
              pBAT: item.pBAT || 0,
              pBNB: item.pBNB || 0,
              pBRL: item.pBRL || 0,
              pCAD: item.pCAD || 0,
              pCHF: item.pCHF || 0,
              pCNY: item.pCNY || 0,
              pDASH: item.pDASH || 0,
              pDCR: item.pDCR || 0,
              pEOS: item.pEOS || 0,
              pETH: item.pETH || 0,
              pEUR: item.pEUR || 0,
              pFCT: item.pFCT || 0,
              pGBP: item.pGBP || 0,
              pHKD: item.pHKD || 0,
              pINR: item.pINR || 0,
              pJPY: item.pJPY || 0,
              pKRW: item.pKRW || 0,
              pLINK: item.pLINK || 0,
              pLTC: item.pLTC || 0,
              pMXN: item.pMXN || 0,
              pNOK: item.pNOK || 0,
              pNZD: item.pNZD || 0,
              pPHP: item.pPHP || 0,
              pRUB: item.pRUB || 0,
              pRVN: item.pRVN || 0,
              pSEK: item.pSEK || 0,
              pSGD: item.pSGD || 0,
              pTRY: item.pTRY || 0,
              pUSD: item.pUSD || 0,
              pXAG: item.pXAG || 0,
              pXAU: item.pXAU || 0,
              pXBC: item.pXBC || 0,
              pXBT: item.pXBT || 0,
              pXLM: item.pXLM || 0,
              pXMR: item.pXMR || 0,
              pXTZ: item.pXTZ || 0,
              pZAR: item.pZAR || 0,
              pZEC: item.pZEC || 0,
            });
          });
          this.activeWalletIndex = localStorage.getItem('walletIndex') || 0;
          if (this.activeWalletIndex >= data.myWallets.length) {
            this.activeWalletIndex = 0;
          }
          this.setActiveFCTWallet(this.activeWalletIndex);
        } else {
          handleWarning(data.error);
          this.fctAddr = '';
        }
      })
      .catch((err) => {
        handleWarning(err);
        this.fctAddr = '';
      });
  };

  @action.bound withdrawConfirm(token, inputCode) {
    return new Promise((resolve, reject) => {
      RealWithdrawConfirm({
        token,
        tfaCode: inputCode,
      })
        .then((data) => {
          if (!data.error) {
            handleSuccess(data.message);
          } else {
            handleWarning(data.error);
          }
          resolve(true);
        });
    });
  }

  @action.bound sendCoin(amount, address, symbol, signature) {
    return new Promise((resolve, reject) => {
      if ((this.myBalances[symbol] || 0) <= 0) {
        handleWarning(`Your ${symbol} balance is insufficient.`);
      } else if (amount <= 0) {
        handleWarning('Please input more than 0 amount.');
      } else if (address === '') {
        handleWarning('Please input withdrawal address.');
      } else {
        WithdrawCoinRequest({
          amount, address, symbol, signature, fromFCTWallet: this.fctAddr,
        })
          .then((data) => {
            if (!data.error) {
              handleSuccess(data.message);
            } else {
              handleWarning('Withdraw request is failed.');
            }
            resolve(true);
          });
      }
    });
  }

  @action.bound convertCoin(amount, symbol, to) {
    return new Promise((resolve, reject) => {
      if ((this.myBalances[symbol] || 0) <= 0) {
        handleWarning(`Your ${symbol} balance is insufficient.`);
      } else if (amount <= 0) {
        handleWarning('Please input more than 0 amount.');
      } else if (to === '') {
        handleWarning('Please input withdrawal address.');
      } else {
        ConversionRequest({
          amount,
          from: symbol,
          to,
          fromFCTWallet: this.fctAddr,
        })
          .then((data) => {
            if (!data.error) {
              handleSuccess(data.message);
            } else {
              handleWarning('Conversion request is failed.');
            }
            resolve(true);
          });
      }
    });
  }

  @action.bound showSnackMsg(msg) {
    this.snackbar({
      message: () => (
        <>
          <span>
            <b>{msg}</b>
          </span>
        </>
      ),
    });
  }
}

export default (authStore, snackbar, coinPriceStore) => {
  const store = new RealDepositStore(authStore, snackbar, coinPriceStore);
  return store;
};
