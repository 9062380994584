import {observable, action, reaction} from 'mobx';
import React from 'react';
import Web3 from 'web3';
import { AUTH_SERVER_URL } from '../config/constants';
import {handleError, handleSuccess, handleWarning} from '../component/Toast';
import {
  EthBalanceRequest, GatewayRequest, GatewayConfirmRequest,
  GatewayHistoryRequest, ClaimSignatureRequest, UpdateClaimStatusRequest,
} from '../lib/ws';
import {
  SYSTEM_ENV,
  contractAddressOfPEG,
  contractAddressOfpUSD,
  contractAddressOfBridge,
} from '../config/constants';
import contractABI from '../lib/abi/pegnettoken.abi.json';
import contractBridgeABI from '../lib/abi/pegnetbridgepool.abi.json';

class Web3Store {
  @observable web3Handle = null;

  @observable publicAddress = '';

  @observable isLoggedIn = false;

  @observable ethBalances = {};

  @observable loadingGatewaySwap = false;

  @observable resultOfFctTxHash = null; // swap result tx hash in Factom blockchain

  @observable resultOfEthTxHash = null; // swap result tx hash in Ethereum blockchain

  @observable gtHistoryData = [];

  hIntval = null;

  hPollingIntval = null;

  contractOfBridge = null;

  constructor(snackbar, authStore) {
    this.snackbar = snackbar;
    this.isLoggedIn = !!localStorage.getItem('authToken');
    this.ethBalances = {
      peg: 0,
      pusd: 0,
    };
    reaction(
      () => ({
        isLoggedIn: authStore.isLoggedIn,
      }),
      (authObj) => {
        this.isLoggedIn = authObj.isLoggedIn;
        if (this.isLoggedIn) {
          this.checkWeb3Handler();
          this.ethBalanceRequest();
          this.setPollingHandler();
        }
      },
    );
    this.checkWeb3Handler();
    this.ethBalanceRequest();
    this.setPollingHandler();
  }

  @action.bound setLoadingGatewaySwap(mode) {
    this.loadingGatewaySwap = mode;
  }

  @action.bound burnEthToken(token, amount) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.web3Handle) {
          await this.setMetamaskWeb3();
        }
        let contract = null;
        switch (token) {
          case 'PEG':
            contract = new this.web3Handle.eth.Contract(contractABI, contractAddressOfPEG);
            break;
          case 'pUSD':
            contract = new this.web3Handle.eth.Contract(contractABI, contractAddressOfpUSD);
            break;
          default:
            break;
        }
        if (contract) {
          const accounts = await this.web3Handle.eth.getAccounts();
          await contract.methods.transfer('0x000000000000000000000000000000000000dEaD', amount * 1e8)
            .send({ from: accounts[0] })
            .on('transactionHash', (transactionHash) => {
              console.log({
                status: true,
                ethTxHash: transactionHash,
              });
              resolve({
                status: true,
                ethTxHash: transactionHash,
              });
            })
            .on('error', (error) => {
              console.log({
                status: false,
                error,
              });
              resolve({
                status: false,
                ethTxHash: null,
              });
            });
        } else{
          resolve({
            status: false,
            ethTxHash: null,
          });
        }
      } catch (e) {
        resolve({
          status: false,
          ethTxHash: null,
        });
      }
    });
  }

  @action.bound setPollingHandler() {
    if (!this.isLoggedIn) return;
    clearInterval(this.hPollingIntval);
    this.hPollingIntval = setInterval(async() => {
      this.ethBalanceRequest();
      this.gatewayConfirmRequest();
    }, 10 * 1000);
  }

  @action.bound gatewayConfirmRequest = () => {
    GatewayConfirmRequest()
      .then((dt) => {
        if (!dt.error) {
          this.gtHistoryData = dt.data ? dt.data.reverse() : dt.data;
        } else {
          this.gtHistoryData = [];
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  @action.bound checkWeb3Handler() {
    if (!this.isLoggedIn) return;
    clearInterval(this.hIntval);
    this.hIntval = setInterval(async () => {
      if (!window.ethereum || !window.ethereum.selectedAddress) this.logout();
      try {
        const web3 = new Web3(window.ethereum);
        const coinbase = await web3.eth.getCoinbase();
        if (!coinbase) this.logout();
        if (this.publicAddress !== coinbase && this.publicAddress && coinbase) {
          this.logout();
        }
        if (SYSTEM_ENV === 'development' && window.ethereum.chainId !== '0x4') {
          this.logout();
        }
        if (SYSTEM_ENV !== 'development' && window.ethereum.chainId !== '0x1') {
          this.logout();
        }
        if (this.publicAddress !== coinbase) {
          this.publicAddress = coinbase;
        }
      } catch (e) {
        this.logout();
      }
    }, 1000);
  }

  @action.bound setPublicAddress(addr) {
    this.publicAddress = addr;
  }

  @action.bound async setMetamaskWeb3() {
    // Check if MetaMask is installed
    if (!window.ethereum) {
      handleSuccess('Please install MetaMask first.');
      return;
    }

    if (!this.web3Handle) {
      try {
        // Request account access if needed
        await window.ethereum.enable();

        // We don't know window.web3 version, so we use our own instance of Web3
        // with the injected provider given by MetaMask
        this.web3Handle = new Web3(window.ethereum);
      } catch (error) {
        handleSuccess('You need to allow MetaMask.');
        return;
      }
    }

    const coinbase = await this.web3Handle.eth.getCoinbase();
    if (!coinbase) {
      handleSuccess('Please activate MetaMask first.');
      return;
    }

    this.publicAddress = coinbase.toLowerCase();
  }

  @action.bound async getSignature() {
    await this.setMetamaskWeb3();

    return fetch(
      `${AUTH_SERVER_URL}/api/auth02/users?publicAddress=${this.publicAddress}`,
    )
      .then((response) => response.json())
      // If yes, retrieve it. If no, create it.
      .then((users) => users.length ? users[0] : null)
      // Popup MetaMask confirmation modal to sign message
      .then(async (data) => {
        if (!data) {
          throw new Error(
            'You need to sign the message to be able to transact.'
          );
        }
        const { publicAddress, nonce } = data;
        try {
          const signature = await this.web3Handle?.eth.personal.sign(
            `pTrader requires signing one-time nonce: ${nonce}`,
            publicAddress,
            '' // MetaMask will ignore the password argument here
          );

          return { publicAddress, signature };
        } catch (err) {
          console.log('err:', err);
          throw new Error(
            'You need to sign the message to be able to transact.'
          );
        }
      })
      .catch((err) => {
        handleSuccess(err);
        return null;
      });
  }

  @action.bound ethBalanceRequest = () => {
    EthBalanceRequest()
      .then((dt) => {
        if (!dt.error) {
          this.ethBalances = {
            peg: dt.data.peg / 1e8,
            pusd: dt.data.pusd / 1e8,
          };
        } else {
          this.ethBalances = {
            peg: 0,
            pusd: 0,
          };
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  checkEthTxHash = (txHash) => {
    return new Promise(async (resolve, reject) => {
      if (!this.web3Handle) {
        await this.setMetamaskWeb3();
      }
      try {
        this.web3Handle.eth.getTransactionReceipt(txHash)
          .then((data) => {
            if (!data || !data.status) {
              return resolve(false);
            }
            return resolve(data.status);
          });
      } catch (e) {
        resolve(false);
      }
    });
  };

  claimPayment = async (signature, amount, nonce, tokenType) => {
    console.log('[claimPayment]', signature, amount, nonce, tokenType);
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.web3Handle) {
          await this.setMetamaskWeb3();
        }
        this.contractOfBridge = new this.web3Handle.eth.Contract(contractBridgeABI, contractAddressOfBridge);
        const accounts = await this.web3Handle.eth.getAccounts();
        await this.contractOfBridge.methods.getBridgeAmount(amount, nonce, signature, tokenType)
          .send({ from: accounts[0] })
          .on('transactionHash', async (transactionHash) => {
            // const status = await this.checkEthTxHash(transactionHash);
            console.log({
              status: true,
              ethTxHash: transactionHash,
            });
            resolve({
              status: true,
              ethTxHash: transactionHash,
            });
          })
          .on('error', (error) => {
            console.log({
              status: false,
              error,
            });
            resolve({
              status: false,
              ethTxHash: null,
            });
          });
      } catch (e) {
        resolve({
          status: false,
          ethTxHash: null,
        });
      }
    });
  };

  @action.bound gatewayRequest = (amount, currency, mode, signature, fctAddr) => {
    this.loadingGatewaySwap = true;
    return new Promise(async (resolve, reject) => {
      if (!currency || !amount) {
        this.loadingGatewaySwap = false;
        return resolve(false);
      }
      let burnTxHash = null;
      if (mode === 'ETHtoFCT') {
        const { status, ethTxHash } = await this.burnEthToken(currency, amount);
        if (!status) {
          this.loadingGatewaySwap = false;
          handleWarning(`Burning ${currency} token is failed.`);
          return resolve(false);
        }
        burnTxHash = ethTxHash;
      }
      console.log('=======> burnTxHash:', burnTxHash);
      GatewayRequest({ amount, currency, mode, signature, fctAddr, burnTxHash })
        .then(async (data) => {
          if (!data.error) {
            this.loadingGatewaySwap = false;
            if (mode === 'FCTtoETH') {
              const {
                fctTxHash, signature, amount, nonce, tokenType,
              } = data.data;
              const { status, ethTxHash } = await this.claimPayment(signature, amount * 1e8, nonce, tokenType);

              if (status) {
                this.updateClaimStatus(signature, ethTxHash);
                this.resultOfFctTxHash = fctTxHash;
                this.resultOfEthTxHash = ethTxHash;
                handleSuccess(data.message);
                resolve(true);
              } else {
                handleWarning('Gateway request is failed.');
                resolve(false);
              }
            } else {
                handleSuccess(data.message);
                const { fctTxHash, ethTxHash } = data.data;
                this.resultOfFctTxHash = fctTxHash;
                this.resultOfEthTxHash = ethTxHash;
                resolve(true);
            }
          } else {
            this.loadingGatewaySwap = false;
            handleWarning('Gateway request is failed.');
            resolve(false);
          }
        })
        .catch((err) => {
          this.loadingGatewaySwap = false;
          handleError(err);
          resolve(false);
        });
    });
  };

  @action.bound getGatewayTxHistory() {
    try {
      GatewayHistoryRequest()
        .then((dt) => {
          if (!dt.error) {
            this.gtHistoryData = dt.data ? dt.data.reverse() : dt.data;
          } else {
            this.gtHistoryData = [];
          }
        });
    } catch (e) {
      console.log('[getGatewayTxHistory]', e);
    }
  }

  updateClaimStatus(signature, ethTxHash) {
    try {
      UpdateClaimStatusRequest({ signature, ethTxHash })
        .then((dt) => {
          if (!dt.error) {
            this.getGatewayTxHistory();
          }
        });
    } catch (e) {
      console.log('[updateClaimStatus]', e);
    }
  }

  @action.bound getClaimSignatureRequest(signature) {
    try {
      ClaimSignatureRequest({ signature })
        .then(async (dt) => {
          if (!dt.error) {
            if (!dt.data) return;
            const { signature, amount, nonce, tokenType } = dt.data;
            const { status, ethTxHash } = await this.claimPayment(signature, amount * 1e8, nonce, tokenType);
            if (status) {
              this.updateClaimStatus(signature, ethTxHash);
            }
          }
        });
    } catch (e) {
      console.log('[getClaimSignatureRequest]', e);
    }
  }

  /**
   *  Logout
   */
  @action.bound logout() {
    clearInterval(this.hIntval);
    this.isLoggedIn = false;
    localStorage.clear();
    window.location.href = '/login';
  }

  @action.bound showSnackMsg(msg) {
    this.snackbar({
      message: () => (
        <>
          <span>
            <b>{msg}</b>
          </span>
        </>
      ),
    });
  }
}

export default (snackbar, authStore) => new Web3Store(snackbar, authStore);
