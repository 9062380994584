import once from 'lodash/once';

import SnackbarStore from './SnackbarStore';
import ViewModeStore from './ViewModeStore';
import AuthStore from './AuthStore';
import RealDepositStore from './RealDepositStore';
import CoinsHandleStore from './CoinsHandleStore';
import HistoryStore from './HistoryStore';
import SecurityStore from './SecurityStore';
import CoinPriceStore from './CoinPriceStore';
import PriceChartStore from './PriceChartStore';
import OrderBookStore from './OrderBookStore';
import TradeBotStore from './TradeBotStore';
import Web3Store from './Web3Store';

const SNACKBARSTORE = 'SnackbarStore';
const VIEWMODESTORE = 'ViewModeStore';
const AUTHSTORE = 'AuthStore';
const REALDEPOSITSTORE = 'RealDepositStore';
const COINSHANDLESTORE = 'CoinsHandleStore';
const HISTORYSTORE = 'HistoryStore';
const SECURITYSTORE = 'SecurityStore';
const COINPRICESTORE = 'CoinPriceStore';
const PRICECHARTSTORE = 'PriceChartStore';
const ORDERBOOKSTORE = 'OrderBookStore';
const TRADEBOTSTORE = 'TradeBotStore';
const WEB3STORE = 'Web3Store';

export const STORE_KEYS = {
  SNACKBARSTORE,
  VIEWMODESTORE,
  AUTHSTORE,
  REALDEPOSITSTORE,
  COINSHANDLESTORE,
  HISTORYSTORE,
  SECURITYSTORE,
  COINPRICESTORE,
  PRICECHARTSTORE,
  ORDERBOOKSTORE,
  TRADEBOTSTORE,
  WEB3STORE,
};

export default once(() => {
  const snackbarStore = SnackbarStore();
  const viewModeStore = ViewModeStore();
  const authStore = AuthStore(snackbarStore.Snackbar);
  const web3Store = Web3Store(snackbarStore.Snackbar, authStore);
  const coinPriceStore = CoinPriceStore(authStore, snackbarStore.Snackbar);
  const realDepositStore = RealDepositStore(authStore, snackbarStore.Snackbar, coinPriceStore);
  const coinsHandleStore = CoinsHandleStore(authStore, snackbarStore.Snackbar);
  const historyStore = HistoryStore(authStore, snackbarStore.Snackbar, realDepositStore);
  const securityStore = SecurityStore(authStore, snackbarStore.Snackbar, realDepositStore);
  const priceChartStore = PriceChartStore(authStore);
  const orderBookStore = OrderBookStore(authStore);
  const tradeBotStore = TradeBotStore(authStore, snackbarStore.Snackbar);

  return {
    [STORE_KEYS.SNACKBARSTORE]: snackbarStore,
    [STORE_KEYS.VIEWMODESTORE]: viewModeStore,
    [STORE_KEYS.AUTHSTORE]: authStore,
    [STORE_KEYS.REALDEPOSITSTORE]: realDepositStore,
    [STORE_KEYS.COINSHANDLESTORE]: coinsHandleStore,
    [STORE_KEYS.HISTORYSTORE]: historyStore,
    [STORE_KEYS.SECURITYSTORE]: securityStore,
    [STORE_KEYS.COINPRICESTORE]: coinPriceStore,
    [STORE_KEYS.PRICECHARTSTORE]: priceChartStore,
    [STORE_KEYS.ORDERBOOKSTORE]: orderBookStore,
    [STORE_KEYS.TRADEBOTSTORE]: tradeBotStore,
    [STORE_KEYS.WEB3STORE]: web3Store,
  };
});
