/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/modules/Auth/pages/AuthPage`, `src/BasePage`).
 */

import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Layout } from './core/layout';
import BasePage from './BasePage';
import { AuthPage } from './pages/Auth';
// import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

import { STORE_KEYS } from './stores';

const Routes = (props) => {
  /* Todo: For authentication, Please change as "const { isLoggedIn } = props;" */
  const {isLoggedIn} = props;

  return (
    <Switch>
      {!isLoggedIn ? (
      /* Render auth page when user at `/auth` and not authorized. */
        <Route>
          <AuthPage />
        </Route>
      ) : (
      /* Otherwise redirect to root page (`/`) */
        <Redirect from="/auth" to="/" />
      )}

      {/* <Route path="/error" component={ErrorsPage}/> */}

      {!isLoggedIn ? (
      /* Redirect to `/auth` when user is not authorized */
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
};

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.AUTHSTORE]: { isLoggedIn },
    }) => ({
      isLoggedIn,
    }),
  ),
)(Routes);
