import { BehaviorSubject, empty } from 'rxjs';

import { createSCConnection, getOpenConnection, destroySocket } from './mm-connection';
import { WS } from '../../config/constants';

let scRealConnection;
let scRealOpenObservable;
export const realNetworkObservable = new BehaviorSubject(false);

let realSocket = null;

export const getSCRealObservable = () => {
  // todo get rid of this;
  const isLoggedIn = !!localStorage.getItem('authToken');
  if (!isLoggedIn) {
    scRealConnection = null;
    scRealOpenObservable = empty();

    return scRealOpenObservable;
  }

  if (scRealConnection) {
    return scRealOpenObservable;
  }

  scRealConnection = createSCConnection({
    port: WS.REAL.PORT,
    hostname: WS.REAL.HOST,
    autoReconnect: true,
  });

  scRealOpenObservable = getOpenConnection(scRealConnection);

  scRealConnection.subscribe(({ socket, connected }) => {
    realSocket = socket;
    realNetworkObservable.next(connected);
  });

  return scRealOpenObservable;
};

export const destroyRConnection = () => {
  scRealConnection = null;
  scRealOpenObservable = empty();
  destroySocket(realSocket);
};

export const DepositAddressRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('DepositAddressRequest', payload);
    cli.once('DepositAddressResponse', (data) => {
      resolve(data);
    });
  });
});

export const WithdrawCoinRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('WithdrawCoinRequest', payload);
    cli.once('RealWithdrawConfirmResponse', (data) => {
      resolve(data);
    });
  });
});

export const ConversionRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('ConversionRequest', payload);
    cli.once('ConversionResponse', (data) => {
      resolve(data);
    });
  });
});

export const HistoryRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('HistoryRequest', payload);
    cli.once('HistoryResponse', (data) => {
      resolve(data);
    });
  });
});

export const RealWithdrawConfirm = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('RealWithdrawConfirm', payload);
    cli.once('RealWithdrawConfirmResponse', (data) => {
      resolve(data);
    });
  });
});

export const GetSecurityRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('GetSecurityRequest', payload);
    cli.once('GetSecurityResponse', (data) => {
      resolve(data);
    });
  });
});

export const Verify2FARequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('Verify2FARequest', payload);
    cli.once('Verify2FAResponse', (data) => {
      resolve(data);
    });
  });
});

export const Enable2FARequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('Enable2FARequest', payload);
    cli.once('Enable2FAResponse', (data) => {
      resolve(data);
    });
  });
});

export const Disable2FARequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('Disable2FARequest', payload);
    cli.once('Disable2FAResponse', (data) => {
      resolve(data);
    });
  });
});

export const ExportAccountRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('ExportAccountRequest', payload);
    cli.once('ExportAccountResponse', (data) => {
      resolve(data);
    });
  });
});

export const ImportAccountRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('ImportAccountRequest', payload);
    cli.once('ImportAccountResponse', (data) => {
      resolve(data);
    });
  });
});

export const VerifyPassRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('VerifyPassRequest', payload);
    cli.once('VerifyPassResponse', (data) => {
      resolve(data);
    });
  });
});

export const AccountSettingsRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('AccountSettingsRequest', payload);
    cli.once('AccountSettingsResponse', (data) => {
      resolve(data);
    });
  });
});

export const ChangeCoinRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('ChangeCoinRequest', payload);
    cli.once('ChangeCoinResponse', (data) => {
      resolve(data);
    });
  });
});

export const ApiTradeRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('ApiTradeRequest', payload);
    cli.once('ApiTradeResponse', (data) => {
      resolve(data);
    });
  });
});

export const PaymentAddressRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('PaymentAddressRequest', payload);
    cli.once('PaymentAddressResponse', (data) => {
      resolve(data);
    });
  });
});

export const WithdrawPaymentRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('WithdrawPaymentRequest', payload);
    cli.once('WithdrawPaymentResponse', (data) => {
      resolve(data);
    });
  });
});

export const PaymentWithdrawConfirm = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('PaymentWithdrawConfirm', payload);
    cli.once('PaymentWithdrawConfirmResponse', (data) => {
      resolve(data);
    });
  });
});

export const EthBalanceRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('EthBalanceRequest', payload);
    cli.once('EthBalanceResponse', (data) => {
      resolve(data);
    });
  });
});

export const GatewayRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('GatewayRequest', payload);
    cli.once('GatewayResponse', (data) => {
      resolve(data);
    });
  });
});

export const FCTBalanceRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('FCTBalanceRequest', payload);
    cli.once('FCTBalanceResponse', (data) => {
      resolve(data);
    });
  });
});

export const GatewayConfirmRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('GatewayConfirmRequest', payload);
    cli.once('GatewayHistoryResponse', (data) => {
      resolve(data);
    });
  });
});

export const GatewayHistoryRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('GatewayHistoryRequest', payload);
    cli.once('GatewayHistoryResponse', (data) => {
      resolve(data);
    });
  });
});

export const ClaimSignatureRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('ClaimSignatureRequest', payload);
    cli.once('ClaimSignatureResponse', (data) => {
      resolve(data);
    });
  });
});

export const UpdateClaimStatusRequest = (payload) => new Promise((resolve) => {
  getSCRealObservable().subscribe((cli) => {
    cli.emit('UpdateClaimStatusRequest', payload);
    cli.once('UpdateClaimStatusResponse', (data) => {
      resolve(data);
    });
  });
});
