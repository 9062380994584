/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, Switch, Redirect } from 'react-router-dom';
import { toAbsoluteUrl } from '../../core/_helpers';
import { ContentRoute } from '../../core/layout';
// import Login from './Login';
import Metamask from './Metamask';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import CheckEmail from './CheckEmail';
import Terms from '../TermsPrivacy/Terms';
import Privacy from '../TermsPrivacy/Privacy';
import '../../core/_assets/sass/pages/login/classic/login-1.scss';

const AuthPage = () => (
  <>
    <div className="d-flex flex-column flex-root">
      {/* begin::Login */}
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
        id="kt_login"
      >
        {/* begin::Aside */}
        <div
          className="login-aside d-flex flex-row-auto bgi-no-repeat p-10 p-lg-10"
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4.jpg')})`,
          }}
        >
          {/* begin: Aside Container */}
          <div className="d-flex flex-row-fluid flex-column justify-content-between login-leftside">
            {/* start:: Aside header */}
            <Link to="/" className="flex-column-auto mt-5">
              <img
                alt="Logo"
                className="max-h-70px"
                src={toAbsoluteUrl('/media/logos/logo_main.png')}
              />
            </Link>
            {/* end:: Aside header */}

            {/* start:: Aside content */}
            <div className="flex-column-fluid d-flex flex-column justify-content-center">
              <h3 className="font-size-h1 mb-5 text-white">
                  Welcome to pTrader!
              </h3>
              <p className="font-weight-lighter text-white opacity-80">
                Automate your PegNet trading with easy-to-use pTrader tools.
                Manage your trades with helpful bots, beautiful visualizations an maximum security.
              </p>
            </div>
            {/* end:: Aside content */}

            {/* start:: Aside footer for desktop */}
            <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
              <div className="opacity-70 font-weight-bold text-white">
                  &copy; 2021 pTrader
              </div>
              <div className="d-flex">
                <Link to="/gdpr-privacy-policy" className="text-white">
                    Privacy
                </Link>
                <Link to="/terms" className="text-white ml-10">
                    Terms
                </Link>
                <Link to="/gdpr-privacy-policy" className="text-white ml-10">
                    Cookie
                </Link>
              </div>
            </div>
            {/* end:: Aside footer for desktop */}
          </div>
          {/* end: Aside Container */}
        </div>
        {/* begin::Aside */}

        {/* begin::Content */}
        <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-auto">

          {/* begin::Content body */}
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <Switch>
              <ContentRoute path="/auth/login" component={Metamask} />
              <ContentRoute path="/auth/registration" component={Registration} />
              <ContentRoute path="/auth/check_email" component={CheckEmail} />
              <ContentRoute
                path="/auth/forgot-password"
                component={ForgotPassword}
              />
              <ContentRoute path="/terms" component={Terms} />
              <ContentRoute path="/gdpr-privacy-policy" component={Privacy} />
              <Redirect from="/auth" exact to="/auth/login" />
              <Redirect to="/auth/login" />
            </Switch>
          </div>
          {/* end::Content body */}

          {/* begin::Mobile footer */}
          <div
            className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5"
          >
            <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2021 pTrader
            </div>
            <div className="d-flex order-1 order-sm-2 my-2">
              <Link to="/gdpr-privacy-policy" className="text-dark-75 text-hover-primary">
                  Privacy
              </Link>
              <Link
                to="/terms"
                className="text-dark-75 text-hover-primary ml-4"
              >
                  Terms
              </Link>
              <Link
                to="/gdpr-privacy-policy"
                className="text-dark-75 text-hover-primary ml-4"
              >
                  Cookie
              </Link>
            </div>
          </div>
          {/* end::Mobile footer */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Login */}
    </div>
  </>
);

export default AuthPage;
