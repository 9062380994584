/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react';
import { Provider } from 'mobx-react';
import { Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ToastContainer, Slide } from 'react-toastify';

import Routes from './Routes';
import Store from './stores';
import { I18nProvider } from './core/i18n';
import { LayoutSplashScreen } from './core/layout';
import theme from './theme/theme';
import 'react-toastify/dist/ReactToastify.css';
import history from './history';

export default function App({ basename }) {
  return (
    /* Provide Redux stores */
    <Provider {...Store()}>
      {/* /!* Asynchronously persist redux stores and show `SplashScreen` while it's loading. *!/ */}
      {/* <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}> */}
      {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
      <React.Suspense fallback={<LayoutSplashScreen />}>
        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
        <Router basename={basename} history={history}>
          {/* This library only returns the location that has been
          active before the recent location change in the current window lifetime. */}
          <MuiThemeProvider theme={theme}>
            {/* Provide `react-intl` context synchronized with Redux state.  */}
            <I18nProvider>
              {/* Render routes with provided `Layout`. */}
              <Routes />
              <ToastContainer transition={Slide} />
            </I18nProvider>
          </MuiThemeProvider>
        </Router>
      </React.Suspense>
      {/* </PersistGate> */}
    </Provider>
  );
}
