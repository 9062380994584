import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: (props) => props.isFull ? '100%' : '66.66%',
    boxSizing: 'border-box',
    color: '#49545a',
    marginBottom: '2rem',
    boxShadow: '0 2px 3px rgba(55,63,67,.16)',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    wordWrap: 'break-word',
    backgroundColor: '#f6f8f9',
    backgroundClip: 'border-box',
    border: '0 solid rgba(55,63,67,.125)',
    borderRadius: '.25rem',
  },
  cardHeader: {
    backgroundColor: '#fff',
    color: 'rgb(73, 84, 90)',
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: '400',
    height: 56,
    lineHeight: 24,
    overflowWrap: 'break-word',
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 16,
    textAlign: 'left',
    textSizeAdjust: '100%',
    borderBottom: '0 solid rgba(55,63,67,.125)',
  },
  cardBody: {
    flex: '1 1 auto',
    minHeight: 1,
    padding: (props) => props.nopadding ? 0 : '1.25rem',
  },
}));

const FrameBox = (props) => {
  const { title, isFull, isHeader = true, nopadding = false, children } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.root} isFull={isFull} isHeader={isHeader} nopadding={nopadding}>
      <div className={classes.cardHeader}>{title}</div>
      <div className={classes.cardBody}>
        {children}
      </div>
    </div>
  )
};
export default FrameBox;
