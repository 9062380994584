import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 50,
    height: 50,
    textAlign: 'center',
    fontSize: 20,
    border: `1px solid ${theme.palette.grey[300]}`,
    margin: theme.spacing(0.5),
    borderRadius: (props) => {
      if (props.order === 'first') return '4px 0 0 4px';
      if (props.order === 'last') return '0 4px 4px 0';
      if (props.order === 'middle') return '0';
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));

const SingleInput = ({ value, order, onChange }) => {
  const classes = useStyles({ order });

  return (
    <input
      className={classes.root}
      type="text"
      value={value || ''}
      maxLength={1}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

SingleInput.propTypes = {
  order: PropTypes.oneOf(['first', 'middle', 'last']),
  onChange: PropTypes.func,
};

SingleInput.defaultProps = {
  order: 'middle',
  onChange: () => {},
};

export default SingleInput;
